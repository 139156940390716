'use strict';

const createErrorNotification = require('base/components/errorNotification');
const formValidation = require('base/components/formValidation');
const recaptcha = require('core/components/recaptcha');

function setupLoginLinkOverride() {
    if (!window.CachedData?.flyoutLoginShowUrl) {
        return;
    }

    var flyoutLoginShowUrl = window.CachedData.flyoutLoginShowUrl;
    var flyoutFlyoutLoginUrl = window.CachedData.flyoutFlyoutLoginUrl;

    // Find all <a> elements where href starts with the value of loginShowUrl
    let selector = 'a[href^="' + flyoutLoginShowUrl + '"]';
    $('#maincontent').find(selector).attr('data-url', flyoutFlyoutLoginUrl)
        .attr('data-target', '#flyout-login')
        .addClass('flyout-login-toggler');
}

function setupRecaptcha() {
    // If the recaptchaSiteKey is missing, then recaptcha is disabled
    if (!window.CachedData?.recaptchaSiteKey) {
        return;
    }

    // Check if the script is already added to avoid duplicate loading
    if (document.querySelector('script[src*="recaptcha/api.js"]')) {
        console.log('Recaptcha script already loaded.');
        return;
    }

    const recaptchaScript = document.createElement('script');
    recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${window.CachedData.recaptchaSiteKey}`;
    recaptchaScript.async = true;
    recaptchaScript.defer = true;

    document.head.appendChild(recaptchaScript);
}

module.exports = {
    setupFlyout: function () {
        console.log('Flyout setup initialized.');

        const body = $('body');
        const flyoutSelector = '.flyout';
        const backdropClass = 'flyout-backdrop';
        const openClass = 'flyout-open';
        const showClass = 'show';

        // Function to hide the flyout
        const hideFlyout = function () {
            const $flyout = $(this);
            const $backdrop = $('.' + backdropClass);

            body.removeClass(openClass);

            $flyout.removeClass(showClass);
            $backdrop.removeClass(showClass).one('transitionend', function () {
                $(this).remove();
            });
        };

        // Function to show the flyout
        const showFlyout = function () {
            const $flyout = $(this);
            const $backdrop = $('<div>', { class: backdropClass });

            body.append($backdrop);
            body.addClass(openClass);

            // Force reflow to enable CSS transitions
            $backdrop[0].offsetWidth;
            $backdrop.addClass(showClass);

            // Event listener to hide flyout when backdrop is clicked
            $backdrop.on('click', () => {
                hideFlyout.call($flyout);
            });

            // Show the flyout
            $flyout.addClass(showClass);
        };

        // Function to toggle the flyout
        const toggleFlyout = function () {
            const $flyout = $(this);
            if ($flyout.hasClass(showClass)) {
                hideFlyout.call($flyout);
            } else {
                showFlyout.call($flyout);
            }
        };

        // jQuery plugin for flyout actions
        $.fn.flyout = function (action) {
            return this.each(function () {
                const $this = $(this);
                switch (action) {
                    case 'show':
                        showFlyout.call($this);
                        break;
                    case 'hide':
                        hideFlyout.call($this);
                        break;
                    case 'toggle':
                        toggleFlyout.call($this);
                        break;
                    default:
                        console.warn(`Unknown flyout action: ${action}`);
                }
            });
        };

        // Set up login link overrides
        setupLoginLinkOverride();

        // Event delegation for toggling flyout
        let handleLoginFlyout = function (event, data) {
            event.preventDefault();

            const targetSelector = $(this).data('target') || '#flyout-login';
            const $flyout = $(targetSelector);
            const $flyoutContent = $flyout.find('.flyout-content');

            // Clear any existing content in the flyout
            $flyoutContent.empty();

            // Toggle flyout visibility
            $flyout.flyout('toggle');
            $flyout.spinner().start();

            // Load login form via AJAX
            const url = $(this).data('url') || data.url;
            const loginMessage = $(this).data('login-message');

            // Create a URL object
            const urlObj = new URL(url, window.location.origin);

            // Check if this is a link override that has a redirect query string parameter...
            const href = $(this).attr('href');
            const hrefUrlObj = new URL(href, window.location.origin);
            const redirectParam = hrefUrlObj.searchParams.get('redirect');

            if (redirectParam) {
                // Append the 'redirect' query parameter to urlObj
                urlObj.searchParams.append('redirect', redirectParam);
            }

            if (loginMessage) {
                // Append the 'loginMessage' query parameter
                urlObj.searchParams.append('loginMessage', loginMessage);
            }

            $.ajax({
                url: urlObj.toString(),
                method: 'GET',
                success(data) {
                    $flyout.spinner().stop();
                    $flyoutContent.html(data);
                    body.trigger('login:reinit');
                    setupRecaptcha();
                },
                error(xhr, status, error) {
                    console.error(`Failed to load login form: ${error}`);
                    $flyout.spinner().stop();
                    createErrorNotification($('.error-messaging'), 'Failed to load login form. Please try again.');
                }
            });
        };
        $(document).on('click', '.flyout-login-toggler', handleLoginFlyout);
        $('body').on('initFlyout', handleLoginFlyout);

        if (window.showLoginFlyout) {
            $('body').trigger('initFlyout', { url: window.showLoginFlyout });
        }

        // Event delegation for creating an account
        $(document).on('click', '.flyout-create-account', function (event) {
            event.preventDefault();

            const $flyout = $('.flyout');
            const $flyoutContent = $flyout.find('.flyout-content');
            $flyout.spinner().start();

            let url = $(this).is('button')
                ? $(this).data('url')
                : $(this).find('a').attr('href');
            $.ajax({
                url,
                method: 'GET',
                success(data) {
                    $flyout.spinner().stop();
                    $flyoutContent.html(data);
                    $('body').trigger('login:reinit');
                },
                error(xhr, status, error) {
                    console.error(`Failed to load create account form: ${error}`);
                    $flyout.spinner().stop();
                    createErrorNotification($('.error-messaging'), 'Failed to load create account form. Please try again.');
                }
            });
        });

        // Event delegation for dismissing flyout via close button
        $(document).on('click', '.flyout-toggler-dismiss', function (event) {
            event.preventDefault();
            const $flyout = $(this).closest(flyoutSelector);
            $flyout.flyout('hide');
        });

        // Event delegation for forgot password
        $(document).on('click', '.flyout-forgot-password', function (event) {
            event.preventDefault();

            const $flyout = $('.flyout');
            const $flyoutContent = $flyout.find('.flyout-content');
            $flyout.spinner().start();

            const url = $(this).find('a').attr('href');
            $.ajax({
                url,
                method: 'GET',
                success(data) {
                    $flyout.spinner().stop();
                    $flyoutContent.html(data);
                    body.trigger('password-reset:reinit');
                },
                error(xhr, status, error) {
                    console.error(`Failed to load forgot password form: ${error}`);
                    $flyout.spinner().stop();
                    createErrorNotification($('.error-messaging'), 'Failed to load forgot password form. Please try again.');
                }
            });
        });

        // Submit forgot password form
        $(document).on('submit', `${flyoutSelector} .reset-password-form`, function (event) {
            event.preventDefault();
            $('.flyout-forgot-password-email-doesnt-exist').addClass('d-none');

            const $form = $(this);
            const actionUrl = $form.attr('action');
            const formData = $form.serialize();
            const $flyout = $form.closest(flyoutSelector);
            const $spinner = $flyout.spinner();
            $spinner.start();

            recaptcha.check(event, {
                method: 'POST',
                url: actionUrl,
                data: formData,
                dataType: 'json',
                success(response) {
                    $spinner.stop();
                    if (response.showEmailDoesntExistError) {
                        $('.flyout-forgot-password-email-doesnt-exist').removeClass('d-none');
                        $('.reset-password-form [type="submit"]').addClass('disabled');
                        $('.reset-password-form #reset-password-email').one('input', function () {
                            $('.reset-password-form [type="submit"]').removeClass('disabled');
                        });
                    } else {
                        // Handle success (e.g., display confirmation message)
                        console.log('Password reset successful:', response);
                        $flyout.find('.flyout-login-page').addClass('d-none');
                        $flyout.find('.flyout-success-email').text($form.find('#reset-password-email').val());
                        $flyout.find('.flyout-forgot-password-success').removeClass('d-none');
                    }
                },
                error(xhr, status, error) {
                    $spinner.stop();
                    console.error(`Password reset failed: ${error}`);
                    createErrorNotification($('.error-messaging'), 'Password reset failed. Please try again.');
                }
            });
        });

        // Submit create account form
        $(document).on('submit', 'form.registration', function (event) {
            event.preventDefault();

            const $form = $(this);
            const actionUrl = $form.attr('action');
            const formData = $form.serialize();
            const $flyout = $('.flyout');
            const $spinner = $flyout.spinner();
            $spinner.start();

            // Trigger pre-validation event
            $form.trigger('login:register', event);

            // Perform reCAPTCHA verification
            recaptcha.check(event, {
                url: actionUrl,
                type: 'post',
                dataType: 'json',
                data: formData,
                success: function (data) {
                    $spinner.stop();
                    if (!data.success) {
                        // Trigger error event and perform form validation
                        $form.trigger('login:register:error', data);
                        formValidation($form, data);
                    } else {
                        // Trigger success event and redirect
                        $form.trigger('login:register:success', data);
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    $spinner.stop();
                    if (err.responseJSON && err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else if (err.responseJSON && err.responseJSON.errorMessage) {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    } else {
                        createErrorNotification($('.error-messaging'), 'An unexpected error occurred. Please try again.');
                    }
                }
            });
        });

        // Event delegation for back button
        $(document).on('click', '.flyout-back-btn', function (event) {
            event.preventDefault();

            const $flyout = $('.flyout');
            const $flyoutContent = $flyout.find('.flyout-content');
            const $backBtn = $(this);
            const url = $backBtn.attr('href');

            $flyout.spinner().start();

            $.ajax({
                method: 'GET',
                url,
                success(response) {
                    $flyout.spinner().stop();
                    $flyoutContent.empty().html(response);
                    $('body').trigger('login:reinit');
                },
                error(xhr, status, error) {
                    $flyout.spinner().stop();
                    console.error(`Back button action failed: ${error}`);
                    createErrorNotification($('.error-messaging'), 'Failed to load the previous content. Please try again.');
                }
            });
        });
    }
};
